import styled from 'styled-components';
import { breakpoint } from 'theme';

import Typography from 'components/atoms/typography';

export const StyledWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.complementary1};
  padding: ${({ theme }) => `${theme.gap.sm} ${theme.gap.sm} ${theme.gap.md}`};
  display: flex;
  justify-content: center;

  ${breakpoint.md} {
    padding-block: ${({ theme }) => theme.gap.md} ${({ theme }) => theme.gap.xl};
  }
`;

export const StyledContent = styled.div`
  max-width: ${({ theme }) => theme.wrapper};
  width: 100%;
`;

export const StyledSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.gap.sm};
  flex-direction: column;
  gap: ${({ theme }) => theme.gap.sm};

  ${breakpoint.md} {
    flex-direction: row;
    margin-top: ${({ theme }) => theme.gap.md};
    gap: ${({ theme }) => theme.gap.md};
  }
`;

export const StyledTitle = styled(Typography)`
  white-space: nowrap;

  ${breakpoint.md} {
    margin-top: 0;
  }
`;

export const StyledSubHeading = styled(Typography)`
  margin-top: ${({ theme }) => theme.gap.md};

  ${breakpoint.md} {
    margin-top: ${({ theme }) => theme.gap.xl};
  }
`;

export const StyledDescription = styled(Typography)`
  max-width: 637px;

  ${breakpoint.md} {
    margin-top: 0;
  }
`;
