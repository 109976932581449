import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { PageContextType } from 'types';
import { JobOffersType } from 'types/home-page';

import Layout from 'components/templates/layout';
import BaseCardListing from 'components/organisms/base-card-listing';
import ContactDetails, { ContactDetailsType } from 'components/organisms/contact-details';
import LetsTalkJobOffers from 'components/organisms/lets-talk-job-offers';
import ManagerSection, { ManagerSectionType } from 'components/organisms/manager-section';
import { BaseCardType } from 'components/molecules/base-card';
import DepartmentBanner, { DepartmentBannerType } from 'components/molecules/department-banner';
import { LetsTalkBannerType } from 'components/molecules/lets-talk-banner';

import { AllJobOfferType } from '../types/job-offers';

type DataProps = {
  departmentDetailsPage: {
    template: {
      departmentDetailsData: {
        departmentBanner: DepartmentBannerType;
        managerSection: ManagerSectionType;
        contactDetails: ContactDetailsType;
        teams: { heading: string; subHeading: string; teamList: { cardPrimary: BaseCardType }[] };
        jobOffersText: JobOffersType;
      };
    };
  };
  allJobOfferType: AllJobOfferType;
  commonComponents: {
    commonComponentsData: { letsTalkBanner: LetsTalkBannerType };
  };
};
const IndustriesHubPage = ({
  pageContext,
  data: {
    departmentDetailsPage: {
      template: {
        departmentDetailsData: {
          departmentBanner,
          managerSection,
          contactDetails,
          teams,
          jobOffersText,
        },
      },
    },
    allJobOfferType,
    commonComponents: {
      commonComponentsData: { letsTalkBanner },
    },
  },
}: PageProps<DataProps, PageContextType>) => {
  const teamsCards = teams.teamList.map(({ cardPrimary }) => cardPrimary);

  return (
    <Layout {...{ pageContext }}>
      <DepartmentBanner pageContext={pageContext} {...departmentBanner} />
      <ManagerSection {...managerSection} mobileMargin="0 0 28px 0" />
      <ContactDetails {...contactDetails} />
      <BaseCardListing
        {...teams}
        smallMarginContent
        showDescription="desktop"
        cards={teamsCards}
        className="department-details__teams"
        variant="secondary"
      />
      <LetsTalkJobOffers
        jobOffersSlider={{
          heading: jobOffersText?.heading,
          linkButton: jobOffersText?.linkButton,
          cards: allJobOfferType.edges,
        }}
        letsTalkBanner={letsTalkBanner}
      />
    </Layout>
  );
};
export const query = graphql`
  query DepartmentDetailsPage($id: String, $commonComponentsSlug: String!) {
    departmentDetailsPage: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_DepartmentDetails {
          departmentDetailsData {
            departmentBanner {
              heading
              subHeading
              text
            }
            managerSection {
              backgroundImage {
                altText
                localFile {
                  url
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              businessCards {
                businessCard {
                  contactList {
                    type
                    link {
                      internal: wpInternal {
                        ... on WpPage {
                          uri
                        }
                      }
                      external {
                        target
                        url
                      }
                      ariaLabel
                    }
                  }
                  fullName
                  image {
                    altText
                    localFile {
                      url
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
                  text
                  position
                  positionTitle
                }
              }
            }
            contactDetails {
              address
              category
              contactList {
                text
                type
              }
              latMap
              lngMap
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                  url
                }
              }
              linkButton {
                icon {
                  name
                }
                label
                link {
                  internal: wpInternal {
                    ... on WpPage {
                      uri
                    }
                  }
                }
              }
            }
            teams {
              subHeading
              heading
              teamList {
                cardPrimary {
                  heading
                  description: text
                  icon {
                    name
                  }
                  linkButton {
                    label
                    icon {
                      name
                    }
                    link {
                      ariaLabel
                      internal: wpInternal {
                        ... on WpPage {
                          uri
                        }
                      }
                      external {
                        target
                        url
                      }
                    }
                  }
                }
              }
            }
            jobOffersText {
              heading
              linkButton {
                icon {
                  name
                }
                label
                link {
                  internal: wpInternal {
                    ... on WpPage {
                      uri
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    allJobOfferType: allWpJobOffer(
      sort: { fields: [date], order: DESC }
      filter: { offerTypes: { nodes: { elemMatch: { name: { eq: "HRK" } } } } }
    ) {
      edges {
        node {
          uri
          title
          date
          offersData {
            consultant
            category
            voivodship
            city
            contracttypes
            salaryfrom
            salaryto
          }
        }
      }
    }
    commonComponents: wpPage(slug: { eq: $commonComponentsSlug }) {
      commonComponentsData {
        letsTalkBanner {
          heading
          description
          linkButton {
            label
            icon {
              name
            }
            link {
              ariaLabel
              internal: wpInternal {
                ... on WpPage {
                  uri
                }
              }
              external {
                target
                url
              }
            }
          }
        }
      }
    }
  }
`;
export default IndustriesHubPage;
