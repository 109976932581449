import React from 'react';

import { BaseCardType } from 'components/molecules/base-card';

import {
  StyledBaseCard,
  StyledContentBaseCardListin,
  StyledSubTitle,
  StyledTitle,
  StyledWrapper,
} from './base-card-listing.styles';

export type StyledTypeCartContent = {
  smallMarginContent?: boolean;
};

export type BaseCardListingType = {
  cards: BaseCardType[];
  showDescription: 'both' | 'desktop';
  heading?: string;
  subHeading?: string;
  className?: string;
  variant?: 'primary' | 'secondary';
} & StyledTypeCartContent;

const BaseCardListing = ({
  cards,
  heading,
  subHeading,
  showDescription,
  className,
  smallMarginContent,
  variant = 'primary',
}: BaseCardListingType) => (
  <StyledWrapper className={className} data-variant={variant}>
    {subHeading ? (
      <StyledSubTitle variant="title6" color="gray40">
        {subHeading}
      </StyledSubTitle>
    ) : null}
    {heading ? (
      <StyledTitle
        variant={variant === 'primary' ? 'title1' : 'title2'}
        color="gray100"
        html={heading}
        className="listing-title"
      />
    ) : null}

    {cards ? (
      <StyledContentBaseCardListin smallMarginContent={smallMarginContent} data-variant={variant}>
        {cards.map((card, idx) => (
          <StyledBaseCard
            {...card}
            variant="services"
            showDescription={showDescription}
            key={card.heading}
            idx={idx}
            itemsLength={cards.length}
            className="listing-base-card"
          />
        ))}
      </StyledContentBaseCardListin>
    ) : null}
  </StyledWrapper>
);

export default BaseCardListing;
