import React from 'react';
import { PageContextType } from 'types';

import Breadcrumb from 'components/atoms/breadcrumb';

import {
  StyledContent,
  StyledDescription,
  StyledSection,
  StyledSubHeading,
  StyledTitle,
  StyledWrapper,
} from './department-banner.styles';

export type DepartmentBannerType = {
  heading: string;
  subHeading: string;
  text: string;
};

export type DepartmentBannerPropsType = {
  pageContext: PageContextType;
} & DepartmentBannerType;

const DepartmentBanner = ({
  heading,
  subHeading,
  text,
  pageContext,
}: DepartmentBannerPropsType) => (
  <StyledWrapper>
    <StyledContent>
      <Breadcrumb {...{ pageContext }} />
      <StyledSubHeading variant="title3" component="h3" color="gray40">
        {subHeading}
      </StyledSubHeading>
      <StyledSection>
        <StyledTitle variant="title1" color="white" component="h1">
          {heading}
        </StyledTitle>
        <StyledDescription variant="body1" color="whiteAlpha72">
          {text}
        </StyledDescription>
      </StyledSection>
    </StyledContent>
  </StyledWrapper>
);

export default DepartmentBanner;
