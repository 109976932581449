import React from 'react';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';

import Button, { ButtonType } from 'components/atoms/button';
import Icon, { IconNameType } from 'components/atoms/icon';
// @ts-ignore
import MarkerIcon from 'components/atoms/icon/svg/marker-icon.svg';
import { WpImageType } from 'components/atoms/wp-image';

import { useScreenService } from 'hooks/use-screen-service';

import { googleMapStyle } from './google-map-styles';

import {
  StyledAddress,
  StyledCategory,
  StyledContact,
  StyledContactCard,
  StyledContactList,
  StyledContactText,
  StyledContent,
  StyledDotsGrid,
  StyledWrapper,
} from './contact-details.styles';

export type ContactDetailsType = {
  address: string;
  category: string;
  contactList: { text: string; type: IconNameType }[];
  image: WpImageType;
  linkButton: ButtonType;
  latMap: number;
  lngMap: number;
};

const containerStyle = {
  width: '100%',
  height: '480px',
};

const optionsMap = {
  disableDefaultUI: true,
  zoomControl: false,
  fullscreenControl: false,
};

const ContactDetails = ({
  address,
  category,
  contactList,
  linkButton,
  latMap,
  lngMap,
}: ContactDetailsType) => {
  const { isMdUp } = useScreenService();
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyA4CBj6i_Qxk5UEI_gDtfGFveicXU3GUh0',
  });

  const center = {
    lat: latMap,
    lng: lngMap,
  };

  return isLoaded && latMap && lngMap ? (
    <StyledWrapper>
      <StyledContent>
        {isMdUp ? <StyledDotsGrid width={2} height={7} color="complementary3" /> : null}
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={18}
          options={{ styles: googleMapStyle, ...optionsMap }}
        >
          <Marker
            position={center}
            icon={{ url: MarkerIcon, scaledSize: new window.google.maps.Size(120, 80) }}
          />
          <StyledContactCard>
            <StyledCategory variant="body3" color="gray60">
              {category}
            </StyledCategory>
            <StyledAddress variant="title4" color="gray80" html={address} />
            {contactList ? (
              <StyledContactList>
                {contactList.map(({ text, type }) => (
                  <StyledContact key={text}>
                    <Icon name={type} />
                    <StyledContactText variant="title4" color="gray80">
                      {text}
                    </StyledContactText>
                  </StyledContact>
                ))}
              </StyledContactList>
            ) : null}
            <Button {...linkButton} />
          </StyledContactCard>
        </GoogleMap>
      </StyledContent>
    </StyledWrapper>
  ) : null;
};

export default ContactDetails;
