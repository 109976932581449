import styled from 'styled-components';
import { breakpoint } from 'theme';

import dotsGrid from 'components/molecules/dots-grid';
import typography from 'components/atoms/typography';
import wpImage from 'components/atoms/wp-image';

export const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledContent = styled.div`
  max-width: 1440px;
  width: 100%;
  position: relative;
`;

export const StyledWpImage = styled(wpImage)`
  height: 312px;

  ${breakpoint.md} {
    height: 480px;
  }
`;

export const StyledContactCard = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  width: 100%;
  position: relative;
  padding: ${({ theme }) => theme.gap.sm};
  filter: drop-shadow(0px 4px 6px rgba(26, 26, 26, 0.06));

  ${breakpoint.md} {
    position: absolute;
    max-width: 463px;
    padding: 24px;
    top: 23%;
    right: 10.5%;
    box-shadow: 0px 6px 20px -2px rgba(26, 26, 26, 0.14), 0px 4px 6px rgba(26, 26, 26, 0.06);
  }
`;

export const StyledCategory = styled(typography)`
  padding-bottom: ${({ theme }) => theme.gap.xs};

  ${breakpoint.md} {
    padding-bottom: ${({ theme }) => theme.gap.sm};
  }
`;

export const StyledAddress = styled(typography)`
  padding-bottom: ${({ theme }) => theme.gap.sm};

  ${breakpoint.md} {
    padding-bottom: 24px;
  }
`;

export const StyledContactList = styled.div`
  padding-bottom: ${({ theme }) => theme.gap.sm};
  gap: ${({ theme }) => theme.gap.xs};

  ${breakpoint.md} {
    padding-bottom: 24px;
  }
`;

export const StyledContact = styled(typography)`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const StyledContactText = styled(typography)``;

export const StyledDotsGrid = styled(dotsGrid)`
  top: -180px;
  left: 32px;
`;
